/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import focusLock from 'dom-focus-lock';
import Masonry from 'masonry-layout';

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	document.documentElement.style.setProperty('--hh', `${document.documentElement.clientHeight + 3}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine'
});

// Headroom
import Headroom from "headroom.js";
var myElement = document.querySelector("header");
var headroom = new Headroom(myElement);
headroom.init();

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '2560px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Masonry
var elem = document.querySelectorAll('.shortcut-block-columns').forEach(el => {
	var msnry = new Masonry(el, {
		// options
		itemSelector: '.shortcut-block-column',
		percentPosition: true
	});
});

// Sliders
document.querySelectorAll('.slider-block').forEach(function (elem) {
	var slider = elem.querySelector('.swiper-container');
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
		var swiper = new Swiper(slider, {
			slidesPerView: 1,
			effect: 'fade',
			speed: 1500,
			pagination: {
				el: '.swiper-pagination-vertical',
				clickable: true
			}
		});
	})();
});

document.querySelectorAll('.vertical-media-slider').forEach(function (elem) {
	var slider = elem.querySelector('.swiper-container');
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;
		var sliderNext = elem.querySelector('.swiper-button-next');
		var sliderPrev = elem.querySelector('.swiper-button-prev');
		var swiper = new Swiper(slider, {
			slidesPerView: 1,
			allowTouchMove: true,
			keyboard: {
				enabled: true
			},
			spaceBetween: 0,
			navigation: {
				nextEl: sliderNext,
				prevEl: sliderPrev
			},
			breakpoints: {
				// when window width is >= 1024px
				1024: {
					slidesPerView: 'auto',
					spaceBetween: 0,
				}
			}
		});
	})();
});

// Info block tabs
document.querySelectorAll('.tab-button').forEach(function (btn) {
	btn.addEventListener("click", function (e) {
		if (!(btn.classList.contains("is-active"))) {

			//Turn off old active
			var activeBtn = document.querySelector('.tab-button.is-active');
			var activeId = activeBtn.id.split('_')[1].toString();
			activeBtn.classList.remove('is-active');
			var activeTab = document.querySelector("#tab_" + activeId);
			activeTab.classList.remove('is-active');

			//Set new to active
			btn.classList.add("is-active");
			var newActiveId = btn.id.split('_')[1].toString();
			var newActiveTab = document.querySelector("#tab_" + newActiveId);
			newActiveTab.classList.add('is-active');
		}
	});
});

// Timeline modals
document.querySelectorAll('.overlay-trigger').forEach(function (trigger) {
	trigger.addEventListener("click", function (e) {
		var linkedOverlay = trigger.parentElement.querySelector('.timeline-overlay');
		if (!(linkedOverlay.classList.contains("open"))) {
			linkedOverlay.classList.add("open");
		}
		else {
			linkedOverlay.classList.remove("open");
		}
	});
});

document.querySelectorAll('.dropdown-trigger').forEach(function (trigger) {
	trigger.addEventListener("mouseover", function (e) {
		trigger.parentElement.querySelector('ul').classList.add('open');
	});
	trigger.parentElement.addEventListener("mouseleave", function (e) {
		trigger.parentElement.querySelector('ul').classList.remove('open');
	});
});

// Homepage animations
var homepage = document.querySelectorAll('.home');
if (homepage.length) {
	(async () => {
		const gsap = (await import(/* webpackMode: "eager" */ './modules/gsap.js')).default;

		// Header ani
		var headerTimeline = gsap.timeline({
			scrollTrigger: {
				animation: headerTimeline,
				trigger: ".header .header-container",
				start: "top top",
				end: "+=3000",
				scrub: true,
				pin: true,
				anticipatePin: 1
			}
		});
		var headerTitle = gsap.to(".header h1", { y: -500, opacity: 0 });
		var introMediaAni = gsap.to(".header .intro-media", { opacity: 0 });
		var transitionMediaAni = gsap.to(".header .transition-media", { opacity: 1, scale: 1 });
		headerTimeline.add(introMediaAni, '-=1');
		headerTimeline.add(transitionMediaAni, '-=.5');
		headerTimeline.add(headerTitle, '-=.5');

		// Media fade block
		var fadeBlock = document.querySelector('.media-fade-block');
		if (fadeBlock) {
			var media = fadeBlock.querySelector('figure');
			if (media.querySelector('img') === null) {
				media = fadeBlock.querySelector('video');
			}
			var mediaFadeTl = gsap.timeline({
				scrollTrigger: {
					animation: mediaFadeTl,
					trigger: media,
					start: "top top",
					end: "+=1000",
					scrub: true,
					pin: true,
					anticipatePin: 1
				}
			});
			var mediaAni = gsap.to(media, { opacity: 1, scale: 1 });
			mediaFadeTl.add(mediaAni);
		};

		// Side scrolling block
		var scrollingBlock = document.querySelector('.side-scrolling-block');
		if (scrollingBlock) {
			var multimediaItems = scrollingBlock.querySelectorAll('.multimedia figure');
			if (multimediaItems !== null) {
				var itemsLength = multimediaItems.length;
				var reduceItems = 1;
				var startPoint = "25%";
				if (screen.width >= 640 && screen.width < 1024) {
					startPoint = "top";
				}
				if (screen.width >= 1024) {
					reduceItems = 2;
				}
				if (screen.width >= 1680) {
					startPoint = "top";
				}
				var scrollLength = (itemsLength - reduceItems) * multimediaItems[0].clientWidth;
				var scrollTl = gsap.timeline({
					scrollTrigger: {
						animation: scrollTl,
						trigger: '.multimedia .wrapper',
						start: "top " + startPoint,
						end: "+="+itemsLength*500,
						scrub: true,
						pin: true,
						anticipatePin: 1
					}
				});
				var translateAni = gsap.to(scrollingBlock.querySelector('.wrapper'), { x: -scrollLength });
				scrollTl.add(translateAni);
			}
		};

		// Media zoom block
		var mediaZoomBlock = document.querySelector('.media-zoom-block figure');
		if (mediaZoomBlock) {
			var zoomMedia = mediaZoomBlock.querySelector('img');
			if (zoomMedia === null) {
				zoomMedia = mediaZoomBlock.querySelector('video');
			}
			if (zoomMedia !== null) {
			
				var mediaZoomTl = gsap.timeline({
					scrollTrigger: {
						animation: mediaZoomTl,
						trigger: mediaZoomBlock,
						start: "top top",
						end: "+=2500",
						scrub: true,
						pin: true,
						anticipatePin: 1
					}
				});

				var textPopups = mediaZoomBlock.querySelectorAll('figcaption span')

				var initialTextUpAni = gsap.to(textPopups[0], { opacity: 0, y: -200 });
				var followupTextOpacity = gsap.to(textPopups[1], { opacity: 1 });
				var followupTextUpAni = gsap.to(textPopups[1], { opacity: 0, y: -200 });
				var lastTextAni = gsap.to(textPopups[2], { opacity: 1 });
				//var zoomAni = gsap.to(media, { scaleX: 0.875, scaleY: 0.74 });
				var zoomAni = gsap.to(zoomMedia, {
					scale: 0.75,
					transformOrigin: "center center",
					duration: 1.5
				});
				var borderAni = gsap.to(zoomMedia, { borderRadius: 20 + "px" });

				mediaZoomTl.add(initialTextUpAni, '-=2');
				mediaZoomTl.add(followupTextOpacity, '-=2');
				mediaZoomTl.add(followupTextUpAni, '-=1.5');
				mediaZoomTl.add(lastTextAni, '-=1.5');
				mediaZoomTl.add(zoomAni, '-=1');
				mediaZoomTl.add(borderAni, '-=1');
			}
		};

		// Eye zoom block
		var eyeZoomBlock = document.querySelector('.eyezoom-block .zoom-container');
		if (eyeZoomBlock) {
			var eyeZoomTl = gsap.timeline({
				scrollTrigger: {
					animation: eyeZoomTl,
					trigger: eyeZoomBlock,
					start: "top top",
					end: "+=1500",
					scrub: true,
					pin: true,
					anticipatePin: 1
				}
			});
			var eyeZoomAni = gsap.to(eyeZoomBlock.querySelector('.eye-mask'), { scale: 9 });
			var eyeFadeAni = gsap.to(eyeZoomBlock.querySelector('.eye-mask-container'), { opacity: 0 });
			eyeZoomTl.add(eyeZoomAni);
			eyeZoomTl.add(eyeFadeAni, '-=.25');
		};

		// GSAP translates (parallax)
		document.querySelectorAll('.gsap-translate-up').forEach(function (translateUpBlock) {
			var transUpTl = gsap.timeline({
				scrollTrigger: {
					animation: transUpTl,
					trigger: translateUpBlock,
					start: "top 100%",
					end: "+=3000",
					scrub: true
				}
			});
			var transUpBlockAni = gsap.to(translateUpBlock, { y: -400 });
			transUpTl.add(transUpBlockAni);
		});

		document.querySelectorAll('.gsap-translate-down').forEach(function (translateDownBlock) {
			var transDownTl = gsap.timeline({
				scrollTrigger: {
					animation: transDownTl,
					trigger: translateDownBlock,
					start: "top 100%",
					end: "+=3000",
					scrub: true
				}
			});
			var transDownBlockAni = gsap.to(translateDownBlock, { y: 500 });
			transDownTl.add(transDownBlockAni);
		});

		document.querySelectorAll('.gsap-translate-down-slow').forEach(function (translateDownSlowBlock) {
			var transDownSlowTl = gsap.timeline({
				scrollTrigger: {
					animation: transDownSlowTl,
					trigger: translateDownSlowBlock,
					start: "top 100%",
					end: "+=1500",
					scrub: true
				}
			});
			var transDownSlowBlockAni = gsap.to(translateDownSlowBlock, { y: 150 });
			transDownSlowTl.add(transDownSlowBlockAni);
		});

		// Gsap fade-up elements
		document.querySelectorAll('.gsap-fade-up').forEach(function (fadeUpBlock) {
			var startPoint = 100;
			if (screen.width >= 1024) {
				startPoint = 50;
			}

			var fadeUpTl = gsap.timeline({
				scrollTrigger: {
					animation: fadeUpTl,
					trigger: fadeUpBlock,
					start: "top "+startPoint+"%",
					end: "+=500",
					scrub: true
				}
			});
			var fadeBlockAni = gsap.to(fadeUpBlock, { opacity: 1, y: 0 });
			fadeUpTl.add(fadeBlockAni);
		});

		document.querySelectorAll('.gsap-fade-up-early').forEach(function (fadeUpEarlyBlock) {
			var startPoint = 100;
			if (screen.width >= 1024) {
				startPoint = 75;
			}

			var fadeUpEarlyTl = gsap.timeline({
				scrollTrigger: {
					animation: fadeUpEarlyTl,
					trigger: fadeUpEarlyBlock,
					start: "top " + startPoint + "%",
					end: "+=500",
					scrub: true
				}
			});
			var fadeEarlyBlockAni = gsap.to(fadeUpEarlyBlock, { opacity: 1 });
			var translateEarlyBlockAni = gsap.to(fadeUpEarlyBlock, { y: 0 });
			fadeUpEarlyTl.add(translateEarlyBlockAni);
			fadeUpEarlyTl.add(fadeEarlyBlockAni, '-=.5');
		});

		// Gsap translate (scale, translateY) elements
		document.querySelectorAll('.gsap-translate').forEach(function (translateBlock) {
			var translateTl = gsap.timeline({
				scrollTrigger: {
					animation: translateTl,
					trigger: translateBlock,
					start: "top 65%",
					end: "bottom bottom",
					scrub: true
				}
			});
			var translateAni = gsap.to(translateBlock, { scale: 1, y: 0 });
			var textAni = gsap.to(translateBlock.querySelector('figcaption'), { opacity: 1, y: 0 });
			translateTl.add(translateAni);
			translateTl.add(textAni);
		});
	})();
}

// Animated product header
var productHeader = document.querySelector('.product-intro-container');
if (productHeader) {
	(async () => {
		const gsap = (await import(/* webpackMode: "eager" */ './modules/gsap.js')).default;

		var tl = gsap.timeline({
			scrollTrigger: {
				animation: tl,
				trigger: "#intro-container",
				start: "top top",
				end: "+=3000",
				scrub: true,
				pin: true
			}
		});

		var headerOpacity = gsap.to(".product-header", { opacity: 0 });
		var headerTitle = gsap.to(".product-header h1", { y: -500 });
		var headerImage = gsap.to(".product-header img", { scale: 2.2 });
		var introContent = gsap.to(".product-intro .container", { opacity: 1 });
		tl.add(headerOpacity);
		tl.add(headerTitle, '-=.5');
		tl.add(headerImage, '-=.5');
		tl.add(introContent, '-=.15');
	})();
}

// Animated timeline
var timeline = document.querySelector('.timeline-block');
if (timeline) {
	(async () => {
		const gsap = (await import(/* webpackMode: "eager" */ './modules/gsap.js')).default;
		var timelinePoints = document.querySelectorAll(".timeline-content");
		var timelineLength = timelinePoints.length * 1080;
		var timelinePath = document.querySelector(".timeline-line");

		var timelineYears = document.querySelectorAll(".timeline-year");
		//var timelineYearTrackingIterator = (1 / timelineYears.length);
		//var timelineYearTrackingPoint = 0;
		var timelineYearIterator = 0;

		var tl = gsap.timeline({
			scrollTrigger: {
				animation: tl,
				trigger: ".timeline",
				start: "top 5%",
				end: "+=" + timelineLength,
				scrub: true,
				pin: true
			}
		});

		var tlpath = gsap.timeline({
			scrollTrigger: {
				animation: tl,
				trigger: ".timeline",
				start: "top 60%",
				end: "+=" + timelineLength,
				scrub: true
			}
		});

		var windowOffset = (0.6 * screen.height);
		document.addEventListener('scroll', function () {
			var currentYear = timelineYears[timelineYearIterator];
			var currentTop = currentYear.getBoundingClientRect().top - document.body.scrollTop;

			if (timelineYearIterator < timelineYears.length - 1) {
				var nextYear = timelineYears[timelineYearIterator + 1];
				var nextTop = nextYear.getBoundingClientRect().top - document.body.scrollTop;
				if (nextTop < windowOffset && !nextYear.classList.contains('circled-red')) {
					nextYear.classList.add('circled-red');
					timelineYearIterator++;
				}
			}

			if (currentTop > windowOffset && currentYear.classList.contains('circled-red') && timelineYearIterator > 0) {
				currentYear.classList.remove('circled-red');
				timelineYearIterator--;
			}
		});


		var timelineContent = gsap.utils.toArray('.timeline-content');
		var iterator = timelineContent.length;
		var firstItemPassed = false;

		var verticalProgressBar = timeline.querySelector('.inner-progress-bar');
		//var verticalProgress = gsap.to(verticalProgressBar, { width: 100+"%"});
		//tl.add(verticalProgress, "0");

		var verticalTLine = gsap.timeline({
			scrollTrigger: {
				animation: verticalTLine,
				trigger: ".timeline",
				start: "top 5%",
				end: "+=" + timelineLength,
				scrub: true
			}
		}).to(verticalProgressBar, { width: 100 + "%" }, 0);

		timelineContent.forEach(el => {
			if (iterator === timelineContent.length) {
				// First blocklist item
				var contentFade = gsap.to(el, {
					keyframes: [
						{ opacity: 1, duration: .79 },
						{ opacity: 0, duration: .01 },
						{ pointerEvents: "none" }
					]
				});
				var fadeDuration = '-=' + .8 * iterator;
				tl.add(contentFade, fadeDuration);
				firstItemPassed = true;
			}
			else if (iterator === 1) {
				// Last blocklist item
				var contentFade = gsap.to(el, { opacity: 1, duration: .01 });
				var pointerEvents = gsap.to(el, { pointerEvents: "all", duration: .01 });
				var fadeDuration = '-=' + .81 * iterator;
				tl.add(contentFade, fadeDuration);
				tl.add(pointerEvents, fadeDuration);
			}
			else if (firstItemPassed) {
				// All blocklist items inbetween
				var contentFade = gsap.to(el, {
					keyframes: [
						{ opacity: 1, duration: .01 },
						{ opacity: 1, duration: .78 },
						{ opacity: 0, duration: .01 }
					]
				});
				var pointerEvents = gsap.to(el, {
					keyframes: [
						{ pointerEvents: "all", duration: .78 },
						{ pointerEvents: "none"}
					]
				});
				var fadeDuration = '-=' + .8 * iterator;
				tl.add(contentFade, fadeDuration);
				tl.add(pointerEvents, fadeDuration);
			}
			iterator--;
		});

		if (timelinePath) {
			var timelinePathAni = gsap.to(timelinePath, { height: timelineLength, duration: timelineLength, ease: "none" })
			tlpath.add(timelinePathAni);
		}
	})();
}

// Counter block animations

// How long you want the animation to take, in ms
const animationDuration = 750;
// Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
const frameDuration = 1000 / 25;
// Use that to calculate how many frames we need to complete the animation
const totalFrames = Math.round(animationDuration / frameDuration);
// An ease-out function that slows the count as it progresses
const easeOutQuad = t => t * (2 - t);

var observer = new IntersectionObserver(function (entries) {
	if (entries[0].isIntersecting === true)
		if (entries[0].target.classList.contains("counter")) {
			entries[0].target.querySelectorAll('.counter-title span').forEach(function (title) {
				if (title && !(title.classList.contains("counted"))) {
					let frame = 0;
					const countTo = parseInt(title.innerHTML, 10);
					// Start the animation running 60 times per second
					const counter = setInterval(() => {
						frame++;
						// Calculate our progress as a value between 0 and 1
						// Pass that value to our easing function to get our
						// progress on a curve
						const progress = easeOutQuad(frame / totalFrames);
						// Use the progress value to calculate the current count
						const currentCount = Math.round(countTo * progress);

						// If the current count has changed, update the element
						if (parseInt(title.innerHTML, 10) !== currentCount) {
							var displayCount = currentCount;
							if (currentCount >= 1000) {
								displayCount = new Intl.NumberFormat().format(currentCount).toString();
							}
							title.innerHTML = displayCount + " ";
						}

						// If we’ve reached our last frame, stop the animation
						if (frame === totalFrames) {
							clearInterval(counter);
						}
					}, frameDuration);
					title.classList.add("counted");
				}
			});
		}
		else if (entries[0].target.classList.contains("rotate")) {
			entries[0].target.querySelector('img').classList.add('rotate-img');
			entries[0].target.classList.add("animated");
		}
		else if (entries[0].target.classList.contains("squeeze")) {
			entries[0].target.querySelector('img').classList.add('squeeze-img');
			entries[0].target.classList.add("animated");
		}
		else if (entries[0].target.classList.contains("wobble")) {
			entries[0].target.querySelector('img').classList.add('wobble-img');
			entries[0].target.classList.add("animated");
		}
}, { threshold: [0] });

document.querySelectorAll(".counter, .rotate, .squeeze, .wobble").forEach(el => {
	observer.observe(el);
});

// Anchor override because GSAP messes with scroll position
let urlSearchParams = new URLSearchParams(window.location.search);
let anchor = urlSearchParams.get("anchor");

if (anchor) {
	(async () => {
		const gsap = (await import(/* webpackMode: "eager" */ './modules/gsap.js')).default;
		var element = document.querySelector('#' + anchor);
		if (element) {
			gsap.to(window, {
				scrollTo: element
			})
		}
	})();
}

let currentAnchor = getAnchor();

function getAnchor() {
	return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
}

if (currentAnchor) {
	(async () => {
		const gsap = (await import(/* webpackMode: "eager" */ './modules/gsap.js')).default;
		var element = document.querySelector('#' + currentAnchor);
		if (element) {
			gsap.to(window, {
				scrollTo: element
			})
		}
	})();
}